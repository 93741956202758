@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;700&display=swap');

.portfolio-card {
    width: 80%;
    margin: 32px 0;
    background-color: #232525;
    padding: 24px;
    box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.205);
}

.portfolio-card .row {
    display: flex;
    justify-content: space-between;
}

.portfolio-card .left-col {
    vertical-align: top;
    width: 60%;
    display: inline-block;
    margin-bottom: 16px;
}

.portfolio-card h4 {
    margin: 0;
    color: white;
}

.portfolio-card p {
    margin-top: 16px;
}

.portfolio-card .preview {
    width: 30%;
    min-height: auto;
    margin-bottom: 32px;
    object-fit: contain;
    vertical-align: top;
    box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.205);
}

.divider-line {
    margin-bottom: 16px;
    border-bottom: 1px solid #FFF;
    width: 100%;
    height: 1px;
    display: block;
}

.portfolio-card .technologies {
    margin: 0 16px 0 0;
    line-height: 32px;
    vertical-align: middle;
    font-family: 'Roboto Mono', monospace;
    color: #CEF2FF; 
    font-size: 0.9em;
}

.portfolio-card .icon {
    height: 28px;
    width: 28px;
    margin: 0 16px 0 0;
    opacity: 0.8;
    transition: 0.3s ease;
}

.portfolio-card .icon:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.3s ease;
}

.portfolio-card .icons {
    min-width: 100px;
    flex-direction: row;
}

.wip p {
    display: inline;
    background: rgb(151,201,248);
    background: linear-gradient(140deg, rgba(151,201,248,1) 17%, rgba(233,156,255,1) 100%);
    box-shadow: 0.5px 0.5px 2px 0px rgba(233,156,255,1);
    color: white;
    font-family: 'Roboto Mono', monospace;
    font-size: 0.9em;
    font-weight: 600;
    margin: auto 8px auto 0;
    padding: 2px 7px 2px 8px;
    border-radius: 2px;
}

.wip h4 {
    display: inline;
}

@media screen and (max-width: 900px) {
    .portfolio-card .row {
        flex-direction: column;
    }

    .portfolio-card .left-col {
        vertical-align: top;
        width: 100%;
        display: inline-block;
    }

    .portfolio-card .preview {
        width: 100%;
        margin-top: 32px;
    }

    .portfolio-card .icon {
        display: inline-block;
        margin: 16px 16px 0 0;
    }
}