@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@700&display=swap');

body {
  overflow-x: hidden
}

.App {
  margin: 0;
  padding: 0;
  background-color: #1E1E1E;
  display: flex;
}

#page {
  display: inline;
  width: 100vw;
  margin: 0 auto;
}

.content {
  max-width: 70vw;
  margin: 0 auto;
}

p, h2, h3 {
  color: white;
  font-family: 'Inter', sans-serif;
  margin: 0;
}

h2 {
  font-size: 2.75em;
}

button {
  border: none;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  color: white;
}

nav {
  position: sticky;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 250px;
  pointer-events: all;
  background-color: #2A2E2D;
  transition: width 0.5s ease;
}

nav.small {
  width: 64px;
  pointer-events: none;
  transition: width 0.5s ease;
}

.open-nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  width: 12px;
  height: auto;
  pointer-events: all !important;
  opacity: 0;
  transition: 0.5s ease;
}

.open-nav.active {
  opacity: 1;
  transition: 0.5s ease;
}

.nav-content {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 75%;
  height: 100vh;
  margin: 0 auto;
  transition: 0.1s ease;
  opacity: 0;
}

.nav-content.hide {
  opacity: 0;
  transition: 0.5s ease;
}

nav .logo {
  margin: 32px auto;
  text-align: center;
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  font-size: 3.6em;
  letter-spacing: 1px;
  text-shadow: 0px 4px 4px black;
}

nav button {
  margin-top: 32px;
  display: inline;
  transition: 0.5s ease;
}

nav button:hover {
  color: #F8A897;
  cursor: pointer;
}

nav span {
  color: #F8A897;
  display: inline;
  opacity: 0;
  transition: 0.5s ease;
}

nav span.active {
  opacity: 1;
  transition: 0.5s ease;
}

nav div.active {
  color: #F8A897;
  transform: translateX(14px);
  transition: 0.5s ease;
}

nav button.active {
  color: #F8A897;
  transition: 0.5s ease;
}

nav div {
  transition: 0.5s ease;
}

nav div:not(.active) {
  transform: translateX(0);
}

#contact-btn {
  flex: 0 0 auto;
  display: inline-block;
  margin: 128px 14px 0 14px;
  padding: 16px 32px;
  color: black;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background: #F8A897; 
  transition: 0.3s ease;
}

#contact-btn:hover {
  cursor: pointer;
  transform: translateY(-3px);
  transition: 0.3s ease;
}

nav img {
  display: block;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

nav img:hover {
  cursor: pointer;
}

/* PAGE CONTENT */

#home {
  height: 100vh;
  min-height: 800px;
  position: relative;
}

.horizontal-block {
  width: 120px;
  height: 120px;
  background-color: pink;
}

#home .welcome {
  position: relative;
  width: 80%;
  max-width: 700px;
  background-color: #FEFFFF;
  margin: 0 auto;
  padding-bottom: 60px;
  box-shadow: 20px 15px 0px #598392;
  z-index: 1;
}

#home h1, #home p, #home h3, #home button {
  color: black;
  margin: 0 100px 0 50px;
}

h1 {
  font-family: 'Inconsolata', monospace;
  font-size: 5em;
  margin-top: 2% !important;
  text-shadow: 2px 3px 1px rgba(0, 0, 0, 0.5);
}

#home p {
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 0.8em;
  padding-top: 25%;
}

#home h3 {
  opacity: 0.5;
  font-family: 'Inconsolata', monospace;
  font-size: 1.8em;
  margin-top: 15%;
}

#home button {
  margin-top: 15%;
  padding: 20px 50px;
  background-color: #97DEF8;
  color: black;
  font-family: 'Inconsolata', monospace;
  opacity: 0.5;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: 0.3s ease;
}

#home button:hover {
  cursor: pointer;
  transform: translateY(-3px);
  box-shadow: 0px 4px 3px #cff0fc;
  transition: 0.3s ease;
}

/* ABOUT */

#about {
  background-color: #232525;
  position: relative;
  z-index: 1;
}

#about div {
  padding: 64px 0;
}

#about .row {
  position: relative
}

#about .left-col {
  display: inline-block;
  width: 60%;
  padding: 0;
}

.about-img {
  display: inline-block;
  position: absolute;
  right: 0;
  width: 30%;
  height: auto;
  vertical-align: top;
}

.down-arrow {
  display: block;
  margin: 0 auto;
  height: 20px;
}

.down-arrow:hover {
  cursor: pointer;
}

#about button {
  margin: 10% 0;
  padding: 20px 50px;
  background-color: #ced155;
  color: black;
  font-family: 'Inconsolata', monospace;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: 0.3s ease;
}

#about button:hover {
  cursor: pointer;
  transform: translateY(-3px);
  transition: 0.3s ease;
}

/* PORTFOLIO */
#portfolio .content {
  padding: 96px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* CONTACT */

#contact {
  background-color: #232525;
}

#contact .content {
  padding: 96px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#contact h2 {
  margin-bottom: 32px;
}

#contact p {
  opacity: 0.5;
}

#contact .row {
  width: 80%;
  text-align: center;
}

#contact button {
  width: 150px;
  margin: 10%;
  padding: 20px 40px;
  background-color: #FFAB99;
  color: rgb(0, 0, 0, 0.5);
  font-family: 'Inconsolata', monospace;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: 0.3s ease;
}

#contact button:hover {
  cursor: pointer;
  transform: translateY(-3px);
  transition: 0.3s ease;
}

#phone {
  background-color: #97DEF8 !important;
}

.vertical-divider {
  width: 1px;
  border-right: 2px solid rgba(255, 255, 255, 0.5);
}

.icons img {
  margin: 32px 24px;
  opacity: 0.5;
  height: 48px;
  width: auto;
  transition: 0.3s ease;
}

.icons img:hover {
  opacity: 1;
  cursor: pointer;
  transition: 0.3s ease;
}

/* FOOTER */
footer {
  background-color: #232525;
}

.footer-columns-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
}

footer p {
  margin-bottom: 16px;

}

footer .icon {
  width: 24px;
  margin-right: 8px;
  opacity: 0.8;
}

footer .icon:hover {
  cursor: pointer;
  opacity: 1;
  transition: 0.3s ease;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-nav button {
  opacity: 0.5;
  color: white;
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 4px;
  transition: 0.3s ease;
}
.footer-nav button:hover {
  cursor: pointer;
  opacity: 1;
  color: #F8A897;
  transition: 0.3s ease;
}

.align-bottom {
  margin: 32px 0 0 0;
}

@media screen and (max-width: 900px) {
  #home {
    overflow: hidden;
  }

  #page {
    margin-right: 32px;
  }
  
  h1 {
    margin-right: 32px !important;
    font-size: 3.8em !important;
  }

  #home h3 {
    font-size: 1.5em !important;
  }

  nav {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
  }

  nav.small {
    width: 32px;
  }

  .open-nav {
    width: 12px;
  }

  #home div {
    max-height: 90vh;
  }

  .content {
    max-width: 90%;
  }

  .about-img {
    display: none;
  }

  #about .left-col {
    width: 100%;
  }

  #about button {
    display: block;
    margin: 32px auto;
  }

  #contact .vertical-divider {
    margin: 0 auto;
    display: block;
    width: 80%;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5)
  }
}